.about-section-7
	.item
		+trans-all
		+radius(16px)
		.img
			max-width: r(186px)
			margin: 0 auto
			figure
				+res-ratio(186,124)
		+on-hover
			+bg(gray-50)
