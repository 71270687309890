.pages,.modulepager
	margin-top: r(32px)
	.pagination
		display: flex
		align-items: center
		justify-content: center
		gap: r(12px)
		li
			list-style: none
			display: inline-block
			text-align: center
			a,span
				justify-content: center
				align-items: center
				display: flex
				text-decoration: none
				width: r(44px)
				height: r(44px)
				font-size: r(16px)
				+radius(50%)
				+trans-all
			&.active,&:hover
				a,span
					+bg-gra(linear-1)

.no-pagination
	.pages
		display: none
