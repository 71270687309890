.landing-template-v1-2
	.item-list
		+row(32px, 32px)
		// max-width: r(738px)
		margin: 0 auto
		justify-content: center
	.item
		display: flex
		flex-direction: column
		+bg(primary-50)
		text-align: center
		+text(primary-500)
		+trans-all
		border-radius: 999px 999px 0 0
		height: 100%
		*
			+trans-all
		.img
			figure
				+res-ratio(1,1)
				+bg(white)
				border-radius: 50%
				border: r(3px) solid color(primary-500)
				position: relative
				img
					position: absolute
					+center(both)
					width: auto
					height: 70%
					object-fit: contain
		.title
			margin-top: r(12px)
			text-align: center
			text-transform: uppercase
			margin-bottom: r(24px)
		.icon
			margin-top: auto !important
		+on-hover
			+text(white)
			+bg(primary-500)
