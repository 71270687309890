.news-item
	box-shadow: 0px r(8px) r(16px) 0px rgba(0, 0, 0, 0.04)
	+bg(white)
	@extend .img-zoom-out
	overflow: hidden
	border-radius: r(16px)
	+trans-all
	position: relative
	&-img
		figure
			+res-ratio(304,240)
			overflow: hidden
	&-caption
		+trans-all
	&-title
		a
			+line(3)
	&-brief
		+line(4)
		height: r(72px)
	.divider
		+trans-all
		width: r(40px)
		height: 1px
		+bg(primary-500)
		opacity: 0
	&-side
		display: flex
		box-shadow: none
		border-radius: 0
		.news-item-img
			+flex-width(120 / 420 * 100%)
			figure
				+res-ratio(120, 95)
				overflow: hidden
				border-radius: r(16px)
		.news-item-title
			a
				+line(2)
		.news-item-brief
			+line(2)
			height: auto
	&-big
		+mq-min(md)
			display: flex
		.news-item-img
			+mq-min(md)
				+flex-width(542 / 864 * 100%)
			figure
				+res-ratio(542, 428)
		.news-item-caption
			flex: 1
		.news-item-brief
			+line(12)
			height: auto
	+on-hover
		// height: 110%
		// z-index: 2
		&:not(.news-item-side)
			+bg(primary-50)
			.divider
				opacity: 1
			.tag-list
				ul
					li
						a
							+bg(white)

.news-slider
	+mq-min(lg)
		margin: r(-16px)
		.swiper-container
			padding: r(16px)
