.job-item
	// display: flex
	// gap: r(42px)
	// align-items: center
	padding: r(24px) r(20px)
	+bg(white)
	+border($color: secondary-100)
	border-radius: r(16px)
	flex-wrap: wrap
	&-icon
		display: grid
		grid-template-columns: r(68px) 1fr
		gap: r(16px)
		align-items: center
	&-caption
		margin-top: r(20px)
	&-button
		text-align: right

	+mq-max(md)
		gap: r(24px)

.job-list
	& + .job-list
		margin-top: r(60px)
		padding-top: r(60px)
		border-top: 1px solid color(gray-200)

.career-form-wrap
	.row
		+gap-override(12px)
	a
		color: #1a76ff
		text-decoration: underline

.career-list-page
	.img
		width: r(222px)
		margin-left: auto
		figure
			margin-top: r(-50px)
			+mq-min(sm)
				margin-right: r(-70px)
				margin-top: r(-20px)

.recruitment-detail-page
	.section-career-form
		display: block !important
