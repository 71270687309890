.landing-template-v1-banner
	.banner
		position: relative
		&-img
			figure
				+mq-max(lg)
					+res-ratio(16,15)
				+mq-min(lg)
					height: r(550px)
				img
					+fit-cover
					object-position: right
		&-caption
			position: absolute
			width: 100%
			+mq-max(lg)
				left: 0
				bottom: r(20px)
			+mq-min(lg)
				+center(Y)
			.content
				+mq-max(lg)
					padding: r(32px)
					border-radius: r(16px)
					+bg-opa(primary-100, 0.75)
