.inpatient-list
	.inpatient-item-col
		+after-first(1)
			+mq-min(md)
				+flex-width(50%)

.inpatient-item
	&-img
		figure
			+res-ratio(592,333)
