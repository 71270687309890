.home-section-1
	padding: r(128px) 0 r(60px)
	+mq-max(lg)
		padding: r(40px) 0
	.item
		display: flex
		gap: r(16px)
		+mq-min(sm)
			padding: r(12px)
		.icon
			+flex-width(r(68px))
			figure
				+circle(68px)
				padding: r(11px)
				display: flex
				+flex-center
				border: 1px solid color(primary-500)
				position: relative
				img
					+trans-all
					position: absolute
					+center(Y)
					max-width: r(40px)
					&.hover
						opacity: 0
		.content
			flex: auto
		+on-hover
			.title
				background: linear-gradient(130deg, #E0BC6A 0%, #FFDA7A 50%, #D7AE5D 100%)
				background-clip: text
				-webkit-background-clip: text !important
				-webkit-text-fill-color: transparent
			.icon
				figure
					background: linear-gradient(#fceff6, #fceff6) padding-box, linear-gradient(130deg, #E0BC6A 0%, #FFDA7A 50%, #D7AE5D 100%) border-box
					border-radius: 50em
					border: 1px solid transparent
				img
					+trans-all
					opacity: 0
					&.hover
						opacity: 1
