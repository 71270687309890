.video
	position: relative
	display: block
	.play-icon
		position: absolute
		+center(both)
		+circle(80px)
		+bg-opa(primary-500,0.4)
		+text(white)
		display: flex
		+flex-center
		+fz(32px)
		border: 2px solid rgba(#fff , 0.8)
		+trans-all
		z-index: 2
