.product-category
	.row
		+gap-override(12px)

.product-keyword-search
	+mq-max(md)
		flex-wrap: wrap
	.product-keyword-input
		flex: 1
		position: relative
		+mq-max(md)
			+flex-width(100%)
		input
			width: 100%
			height: r(42px)
			border-radius: 999px
			border: 1px solid color(primary-100)
			padding: 0 r(124px) 0 r(24px)
			@extend .body-4
			+placeholder
				+text(gray-400)
			+mq-max(md)
				height: r(48px)
		.btn
			position: absolute
			top: 0
			right: 0
			height: 100%
			+mq-max(md)
				top: 0
				right: 0
				bottom: 0

.product-category
	+mq-max(md)
		flex-wrap: wrap
	label
		white-space: nowrap
	select
		flex: 1
		height: r(42px)
		border-radius: 999px
		border: 1px solid color(primary-100)
		padding: 0 r(24px)
		@extend .body-4
		background-color: white
		+select-bg("../img/sl.svg", calc(100% - 1.25rem))
		background-size: r(15px) r(24px)
		max-width: r(208px)
		+mq-max(md)
			height: r(48px)
			+flex-width(100%)
			max-width: none
