.brand-slider
	.swiper-wrapper
		transition-timing-function: linear
	.item
		.img
			height: r(120px)
			display: flex
			+flex-center
			img
				max-height: 100%
