.landing-template-v1-7
	.item
		+odd
			+mq-min(lg)
				.item-content
					padding-left: r(32px)
		+even
			.row
				flex-direction: row-reverse
			+mq-min(lg)
				.item-content
					padding-right: r(32px)
