.home-section-2
	+mq-max(lg)
		+bg(gray-50)
		.col-left
			order: 2
		.col-right
			order: 3
		.col-center
			order: 1
	.item-list
		margin-top: r(96px)
		+mq-max(lg)
			margin-top: r(40px)
	.btn-wrap
		margin-top: r(140px)
		position: relative
		z-index: 2
		+mq-max(lg)
			margin-top: r(32px)
	.section-heading
		position: relative
		z-index: 2
	.section-content
		position: relative
		z-index: 2
		+mq-min(lg)
			&::before
				+pseudo
				width: 90vw
				height: 90vw
				+center(both)
				z-index: 1
				background: linear-gradient(180deg, rgba(249, 243, 247, 0.00) 8.31%, rgba(249, 243, 247, 0.80) 30.13%, #F9F3F7 52.6%, rgba(249, 243, 247, 0.80) 67.64%, rgba(249, 243, 247, 0.05) 89.08%)
				border-radius: 50%
				+mq-min(2xl)
					+box(1312px)
	.item
		position: relative
		z-index: 2
		.divider
			width: r(40px)
		&-small
			display: flex
			align-items: center
			.img-wrap
				+flex-width(r(160px))
				@extend .img-zoom-out
				overflow: hidden
				.img
					border: r(4px) solid color(white)
					border-radius: 50%
					overflow: hidden
				figure
					+res-ratio(1,1)
			.caption
				flex: auto
				.content
					padding: r(16px) r(24px)
					justify-content: center
					height: 100%
			& + .item-small
				margin-top: r(40px)
				+mq-max(lg)
					margin-top: r(24px)
			&-left
				background: linear-gradient(90deg, #FFF 41.98%, rgba(255, 255, 255, 0.00) 100%)
				border-radius: 800px 0px 0px 800px
				+mq-min(md)
					border-radius: 0px 800px 800px 0px
					background: linear-gradient(270deg, #FFF 41.78%, rgba(255, 255, 255, 0.00) 100%)
					text-align: right
					flex-direction: row-reverse
					.content
						align-items: flex-end
				+mq-min(lg)
					&:nth-child(2n+1)
						transform: translateX(r(48px))
			&-right
				border-radius: 800px 0px 0px 800px
				background: linear-gradient(90deg, #FFF 41.98%, rgba(255, 255, 255, 0.00) 100%)
				+mq-min(lg)
					&:nth-child(2n+1)
						transform: translateX(r(-48px))
		&-large
			padding-top: 100%
			position: relative
			text-align: center
			z-index: 1
			&::before
				@extend .background-cover
				+mq-min(lg)
					+pseudo
					+box(672px)
					+center(both)
					z-index: -1
					background-image: url(../img/home-bg.png)
			.item-inner
				border-radius: 50%
				+bg(white)
				border: r(10px) solid color(white)
				overflow: hidden
				position: absolute
				+fill
			.img-wrap
				height: 204 / 425 * 100%
				overflow: hidden
				@extend .img-zoom-out
				+mq-max(sm)
					height: 33.333333%
			.caption
				padding: r(12px) r(68px)
			.brief
				font-size: r(12px)
				line-height: 1.333
				+line(7)
				+mq-max(lg)
					+fz-lh(14px,20px)

.department-page
	.home-section-2
		.btn-wrap
			display: none
