.form-group
	label
		@extend .body-3
		margin-bottom: r(12px)
		display: inline-block
		small
			color: red
	+all-text-inputs
		height: r(44px)
		width: 100%
		background: white
		border: 1px solid color(gray-100)
		padding: 0 r(24px)
		border-radius: 999px
		+fz-14(14px)
		+placeholder
			+text(gray-400)
			opacity: 1
		+mq-max(sm)
			height: r(54px)
	select
		height: r(44px)
		width: 100%
		background-color: #fff
		border: 1px solid color(gray-100)
		padding: 0 r(24px)
		+text(gray-500)
		+fz-14(14px)
		border-radius: 999px
		+select-bg($bg: "../img/sl-gray.svg")
		background-size: r(16px) r(20px)
		+mq-max(sm)
			height: r(54px)
	textarea
		height: r(112px)
		padding: r(12px) r(24px)
		border-radius: r(20px)

.frm-btnwrap
	margin-top: r(20px)
	.label
		display: none

.frm-btn
	position: relative
	display: inline-block
	margin-left: auto
	min-width: r(180px)
	+mq-max(lg)
		min-width: r(200px)
	&:before
		+center(Y)
		right: r(64px)
		content: '\e901'
		font-family: "icomoon"
		+text(white)
		pointer-events: none
		font-weight: 300
		+fz(16px)
		+trans-all
	input[type="submit"]
		+trans-all
		+bg(primary-500)
		+text(white)
		border-radius: 22px
		border: 0
		height: r(44px)
		width: 100%
		cursor: pointer
		padding: 0 r(48px) 0 r(24px)
		+fz(18px)
		+mq-max(sm)
			height: r(54px)
	+on-hover
		&:before
			+text(white)
			content: '\e900'
		input[type="submit"]
			+bg(primary-500)
			+text(white)
			border-radius: r(4px)

.RadCaptcha
	> div
		> div
			display: flex
			gap: r(16px)
			align-items: center
			a
				font-size: 0
				&:before
					+icon('\f021')
					+fz(32px)
					+text(gray-500)

.frm-captcha
	margin-top: r(20px)
