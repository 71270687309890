.doctor-item
	+bg(white)
	+trans-all
	box-shadow: 0px r(8px) r(16px) 0px rgba(0, 0, 0, 0.04)
	+bg(gray-50)
	&-img
		figure
			overflow: hidden
	img
		+trans-all
	.divider
		+trans-all
		width: r(40px)
		height: 1px
		+bg(primary-500)
		opacity: 0
	+on-hover
		img
			transform: scale(1.05)
		.divider
			opacity: 1

.doctor-slider
	+mq-min(lg)
		margin: r(-16px)
		.swiper-container
			padding: r(16px)
