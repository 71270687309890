.product-item
	&-title
		+trans-all
		a
			+line(2)
		+on-hover
			+text(primary-500)
	&-horizontal
		display: flex
		border-radius: r(16px)
		overflow: hidden
		border: 1px solid color(primary-50)
		.product-item-img
			+flex-width(336 / 640 * 100%)
			+mq-max(sm)
				+flex-width(37.5%)
			figure
				width: 100%
				// height: 100%
				img
					+fit-cover
					object-position: top
		.product-item-caption
			+flex-width(304 / 640 * 100%)
			+mq-max(sm)
				+flex-width(62.5%)
			&-inner
				padding: r(32px) r(24px)
				height: 100%
				+mq-max(sm)
					padding: r(16px)
				+mq-min(lg)
					display: flex
					flex-direction: column
	&-buttons
		+mq-max(xl)
			flex-wrap: wrap
	&-vertical
		border-radius: r(16px)
		overflow: hidden
		box-shadow: 0px r(8px) r(16px) 0px rgba(0, 0, 0, 0.04)
		+bg(white)
		display: flex
		flex-direction: column
		.product-item-caption
			padding: r(24px)
			border: 1px solid color(primary-50)
			+mq-max(lg)
				padding: r(16px)
			&-inner
				display: flex
				flex-direction: column
				justify-content: space-between
				height: 100%

		.product-item-img
			figure
				a
					display: block
					+res-ratio(416,312)

.product-slider
	+mq-min(lg)
		margin: r(-16px)
		.swiper-container
			padding: r(16px)
