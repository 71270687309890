.cart-login
	width: r(500px)
	max-width: 100%
	background: #fff
	border-radius: 10px
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05)
	margin: 0 auto

	.login-heading 
		padding: r(20px) r(24px) r(12px)
		border-bottom: 1px solid #ebebeb
		font-size: r(18px)
		color: color(main)
		text-transform: uppercase
		font-weight: 700

	.login-form
		padding: r(24px)
		.select-group
			line-height: 1.375
			ul
				+layout-col(8px)
				li, li > span
					display: flex
					align-items: center
					label
						margin-left: r(8px)
					input
						position: relative
						margin: 0

		.module-title
			text-transform: uppercase
			margin-top: r(24px)
			margin-bottom: r(16px)
			font-weight: 700

		.form-group
			& + .form-group
				margin-top: r(20px)
			label
				display: block
				margin-bottom: r(8px)
			input[type="text"],input[type="password"]
				width: 100%
				height: r(42px)
				border-radius: 5px
				border: 1px solid #e1e1e1
				background-color: #fafafa
				padding: 0 20px
				&::placeholder
					color: #999999
			&.form-link
				display: flex
				justify-content: space-between
			.register-link,#ctl00_mainContent_ctl00_LoginCtrl_lnkPasswordRecovery
				font-size: 14px
			#ctl00_mainContent_ctl00_LoginCtrl_lnkRegisterExtraLink
				color: #00c1ef
				text-decoration: underline

		input[type="submit"]
			height: r(42px)
			width: 100%
			background: #fff
			border-radius: 5px
			border: 2px solid color(primary-500)
			+text(primary-500)
			font-weight: 700
			margin-top: r(20px)
			font-size: r(16px)
			cursor: pointer

.social-login
	display: none
	a
		// display: flex
		// height: 40px
		// border-radius: 20px
		width: 100%
		span
			color: #fff
		& + a
			margin-top: r(20px)
		// em
		// 	margin-right: 10px
		// 	font-size: r(18px)
		// 	color: #fff
		&.facebook-login
			background: #4267B2
		&.google-login
			background: #df4a32

.input-group-append
	display: none