.career-detail-info
	padding: r(22px) r(20px)
	box-shadow: 4px 4px 16px 6px rgba(0, 0, 0, 0.08)
	border-radius: r(12px)
	ul
		+row(20px,20px)
		li
			+flex-width(50%)
			display: flex
			+mq-max(md)
				+flex-width(100%)
			em
				width: r(24px)
				+text(primary-500)
				position: relative
				top: r(2px)
			strong
				display: inline-block
				width: r(150px)
			span
				padding-left: r(28px)

.career-detail-attributes
	.attr-item
		display: flex
		gap: r(16px)
		&-title
			+mq-min(md)
				+flex-width(208 / 824 * 100%)
		&-content
			flex: 1
		+mq-max(md)
			flex-direction: column

.career-other-list
	.divider
		height: 1px
		width: r(190px)
		+bg(primary-500)
		margin-bottom: r(20px)

.career-other-item
	ul
		display: flex
		flex-wrap: wrap
		gap: r(16px) r(32px)
		li
			display: flex
			gap: r(4px)
			align-items: center
	& + .career-other-item
		margin-top: r(20px)
		padding-top: r(20px)
		border-top: 1px solid color(gray-100)
