.about-section-2
	position: relative
	&:after
		+pseudo
		bottom: 0
		width: 100%
		top: r(-108px)
		z-index: 1
		background: url(../img/about-bg-2.png)
		@extend .background-cover
		pointer-events: none
	.container
		position: relative
		z-index: 2