.manual-schedule-content
	.article-content
		table
			text-align: center
			background: rgba(246, 238, 244, 0.50)
			th
				+bg(primary-500)
				+text(white)
			td,th
				border: 1px solid color(primary-200)
