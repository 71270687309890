.press-slider
	.swiper-container
		padding: r(20px)
		margin: r(-20px)
	.item
		box-shadow: 0px 4px r(20px) 0px rgba(0, 0, 0, 0.08)
		.img
			figure
				+res-ratio(416,219)
				background: #000
				img
					opacity: 0.6
				figcaption
					position: absolute
					+center(both)
					width: 100%
					text-align: center
					+text(white)
					padding: 0 r(20px)
		.content
			display: flex
			flex-direction: column
			justify-content: space-between
			.article-content
				ul
					padding-left: 1rem
