.department-item
	&-icon
		padding-top: 100%
		position: relative
		+bg(secondary-100)
		border-radius: r(16px)
		display: block
		figure
			position: absolute
			+center(both)
			display: block
			width: r(100px)
