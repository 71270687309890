.global-header
	position: relative
	+bg(white)
	padding: r(12px) 0 0
	box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.10)
	z-index: 51
	+mq-max(lg)
		padding: r(8px) 0

.logo
	width: r(140px)
	a
		display: block
		+res-ratio(98,96)
	.ModuleContent
		display: grid
		grid-template-columns: repeat(2, 1fr)
		gap: r(12px)

.header-wrap
	position: relative
	z-index: 51

.header-right-top
	padding: r(4px)
	border-radius: 999px
	+bg(gray-50)
	gap: r(72px)
	display: flex
	align-items: center
	justify-content: space-between
	+mq-max(lg)
		padding: r(8px) r(16px)

.header-util-wrapper
	display: flex
	align-items: center
	justify-content: space-between
	gap: r(4px)
	+mq-max(lg)
		gap: r(12px)

.header-right-bottom
	display: flex
	align-items: center
	justify-content: space-between
	gap: r(32px)
	+mq-max(lg)
		justify-content: flex-end
		margin-top: r(8px)
		gap: r(16px)

.external-links,.language
	ul
		display: flex
		gap: r(4px)
		li
			&.active
				a
					+text(primary-500)
				+on-hover
					a
						+text(white)
.external-links
	ul
		gap: r(8px)
		+mq-max(lg)
			flex-wrap: wrap
			> *
				+flex-width(48%)
			li
				a
					width: 100%

.working-hours
	+mq-min(lg)
		white-space: nowrap
		font-size: r(14px) !important

.language
	ul
		li
			a
				@extend .body-5
				text-transform: uppercase
				font-weight: 700
				opacity: 0.5
				+text(gray-800)
				display: inline-flex
				align-items: center
				height: r(20px)
				border-bottom: 1px solid transparent
			&.active
				a
					+text(primary-500)
					border-bottom: 1px solid color(primary-500)
					opacity: 1

.menu-wrapper
	flex: 1

.menu
	> ul
		display: flex
		gap: r(32px)
		justify-content: flex-end
		+mq-max(lg)
			flex-direction: column
			gap: r(16px)
		> li
			> a
				display: inline-flex
				height: r(64px)
				align-items: center
				font-weight: 700
				+fz(16px)
				border-bottom: 1px solid transparent
				+trans-all
				+mq-max(lg)
					height: auto
					padding-bottom: r(8px)
			&.active
				> a
					+text(primary-500)
					+mq-min(lg)
						border-bottom: 1px solid color(primary-500)
			+on-hover
				> a
					+text(primary-500)
					border-bottom: 1px solid color(primary-500)

.has-sub
	position: relative
	display: flex
	align-items: center
	justify-content: space-between
	flex-wrap: wrap
	.toggle-sub-menu
		+mq-min(lg)
			display: none
		+mq-max(lg)
			+box(32px)
			display: flex
			+flex-center
			margin-left: auto
			+bg(primary-500)
			+text(white)
			margin-left: auto
	.sub-menu
		display: none
		z-index: 2
		+bg(gray-50)
		+text(primary-500)
		border-radius: 0 0 r(16px) r(16px)
		+mq-max(lg)
			+flex-width(100%)
			margin-top: r(16px)
		+mq-min(lg)
			position: absolute
			top: 100%
			left: 0
			padding-top: r(2px)
		ul
			padding: r(20px) r(16px) r(20px) r(32px)
			+layout-col(16px)
			list-style: disc
			+mq-max(lg)
				margin-top: r(8px)
			// 	padding: 0 0 0 r(16px)
			// 	margin-top: r(16px)
			// 	border-left: 1px dashed color(gray-50)

			li
				a
					@extend .body-4
					white-space: nowrap
				&.active
					> a
						text-decoration: underline
				+on-hover
					> a
						text-decoration: underline
		&-2-cols
			.row
				margin-bottom: 0
				> *
					padding-bottom: 0
			+mq-max(lg)
				padding: r(20px) 0
				.row
					> *
						& + *
							margin-top: r(16px)
				ul
					padding: 0 r(32px) 0 r(48px)
			+mq-min(lg)
				width: max-content
				.row
					> *
						max-width: none !important
						flex: 1
						& + *
							ul
								border-left: 1px solid color(gray-100)
				ul
					padding: r(20px) r(32px) r(20px) r(48px)
	+on-hover
		.sub-menu
			display: block

// .search-popup
// 	position: fixed
// 	z-index: 1001
// 	top: 0
// 	left: 0
// 	width: 100vw
// 	height: 100vh
// 	pointer-events: none
// 	display: none

.search-toggle
	+box(20px)
	display: inline-flex
	+flex-center

.search-container
	position: absolute
	top: 100%
	right: 0
	display: none

.searchbox
	pointer-events: all
	position: relative
	input
		width: r(360px)
		height: r(40px)
		padding: 0 r(72px) 0 r(20px)
		+fz(16px)
		border-radius: r(40px)
		border: 1px solid color(gray-200)
	button
		position: absolute
		top: 0
		right: 0
		height: 100%
		width: r(48px)
		background: transparent
		border: 0
		+fz(16px)

.suggestsearch
	position: absolute
	top: 100%
	padding: r(20px)
	background: #fff
	border-radius: r(4px)
	border: 1px solid #eeeeee
	box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15)
	margin-top: r(12px)
	z-index: 10
	display: none
	width: 100%
	max-height: 30vh
	overflow: auto
	line-height: 1.375
	right: 0
	+scrollbars(4px, color(primary-500), color(gray-50))
	.keyword-list
		line-height: 1.375
		h2
			margin-bottom: r(16px)
		& + .keyword-list
			margin-top: r(24px)
		ul
			li
				& + li
					margin-top: 10px
				a
					font-size: r(16px)
					color: #666
					font-weight: 400
					.img
						display: flex
						align-items: center
						justify-content: center
						flex: 0 0 r(60px)
						img
							max-height: r(60px)
					.text
						flex: auto

.menu-toggle
	+box(44px)
	display: flex
	+flex-center
	+bg(primary-700)
	+text(white)
	+fz(24px)

.cta-toggle
	+fz(24px)
	+box(44px)
	display: flex
	+flex-center
	+bg(gray-300)
	+text(white)

.menu-mobile
	position: fixed
	padding: r(48px) r(24px)
	top: 0
	left: -100%
	width: 320px
	height: 100%
	background: linear-gradient(to bottom, rgba(226,199,216,0.887) 0%,rgba(241,228,236,0.946) 100%)
	overflow: auto
	z-index: 1000
	+trans-all
	&.show
		left: 0
	.close-menu
		position: absolute
		+box(44px)
		display: flex
		+flex-center
		+bg(gray-50)
		top: 0
		right: 0
		font-size: r(24px)
	.menu-mobile-header
		margin-bottom: r(20px)
		display: flex
		align-items: center
		justify-content: space-between
	.menu-mobile-body
		+layout-col(16px)
			padding-top: r(16px)
			border-top: 1px solid color(white)

.backdrop
	+fill
	position: fixed
	opacity: 0.75
	+bg(gray-900)
	z-index: 999
	display: none
