.cart-section,.checkout-section,.checkout-complete-section
	.card-item
		@extend .body-3
		border-radius: r(16px)
		+bg(white)
		box-shadow: 0px 4px 18px 2px rgba(0, 0, 0, 0.06)
		padding: r(32px)
		& + .card-item
			margin-top: r(32px)
		.card-heading
			padding-bottom: r(20px)
			margin-bottom: r(20px)
			border-bottom: 1px solid color(primary-100)
			.card-title
				@extend .heading-5
				font-weight: 700
				+text(primary-500)
				display: flex
				align-items: center
				gap: r(12px)

.cart-product-header
	display: flex
	gap: r(20px)
	font-weight: 500
	margin-bottom: r(16px)
	padding-bottom: r(16px)
	border-bottom: 1px solid color(gray-50)
	+remove(md,max)
	&-img
		+flex-width(r(160px))
	&-content
		display: flex
		flex: 1
		gap: r(20px)
		align-items: center
	&-name
		flex: 1
	&-price
		+flex-width(r(160px))
	&-subtotal
		+flex-width(r(160px))
	&-amount
		+flex-width(r(160px))
	&-btn
		+flex-width(r(60px))
		text-align: right

.cart-product-item
	gap: r(32px)
	font-weight: 500
	& + .cart-product-item
		margin-top: r(20px)
		padding-top: r(20px)
		border-top: 1px solid color(primary-100)
	&-img
		+flex-width(r(160px))
		a
			display: block
			+res-ratio(160,120)
			width: 100%
		+mq-max(md)
			align-items: flex-start !important
	&-content
		flex: 1
		gap: r(20px)
		+mq-max(md)
			gap: r(8px)
	&-name
		flex: 1
	&-price
		+layout-col(8px)
		+flex-width(100%)
		+mq-min(md)
			+flex-width(r(160px))
		del
			+text(gray-400)
		.discount
			display: inline-block
			padding: r(2px) r(4px) r(2px) r(4px)
			+bg(primary-600)
			+text(white)
			@extend .body-5
			font-weight: 700
			border-radius: r(4px)
	&-subtotal
		+text(primary-600)
		+flex-width(100%)
		+mq-min(md)
			+flex-width(r(120px))
	&-amount
		+flex-width(r(144px))
		.spin-input-wrap
			width: r(144px)
	&-btn
		+text(gray-400)
		+flex-width(r(60px))
		+mq-min(md)
			text-align: right

.cart-order-info
	ul
		+layout-col(12px)
