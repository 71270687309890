.item-image-caption-slider
	.item
		display: flex
		gap: r(12px)
		align-items: center
		padding: r(10px)
		+bg(white)
		border-radius: r(4px)
		.img
			+flex-width(r(90px))

.swiper-pagination-landing
	position: static
	justify-content: flex-start
