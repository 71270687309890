.about-section-5
	.branch-list
		.row
			+gap-override(32px,0px)
	.branch-col
		+mq-min(lg)
			&:nth-child(n+3)
				padding-top: r(28px) !important
				margin-top: r(28px)
				border-top: 1px solid color(primary-100)
	.branch-item
		padding: r(20px)
		+layout-col(12px)
		align-items: flex-start
		+trans-all
		border-radius: r(16px)
		+on-hover
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25)
		.year
			padding: r(4px) r(16px)
			+bg-gra(linear-1)
			border-radius: 999px
			font-weight: 700
			@extend .body-4
		.img
			margin-top: r(16px) !important
			width: 100%
			figure
				+res-ratio(264,136)

.dealer-map
	position: relative
	#dealerMap
		cursor: grab
		max-width: 100%
		height: 100%
	&-control
		position: absolute
		bottom: 0
		+center(X)
		display: flex
		+content-margins
			padding-left: r(8px)
			margin-left: r(8px)
			border-left: 1px solid color(gray-400)
		a
			font-size: r(20px)
			display: inline-flex
			+box(24px)
			+flex-center
			+text(primary-500)

.map-tooltip-container
	padding: r(16px)
	+bg(primary-50)
	+radius(r(16px))
	+text(gray-900)
	position: relative
	min-width: r(452px)

.tippy-box[data-theme="custom-theme"]
	background-color: transparent
	pointer-events: auto
	.tippy-content
		padding: 0
