.faq-list
	.accordion-item
		.accordion-title
			cursor: pointer
			span
				position: relative
		&.active
			.accordion-title
				+text(primary-500)
				span
					&::before
						content: '\f077'

.faq-support
	ul
		+layout-col(16px)
		li
			a
				display: flex
				gap: r(16px)
				font-weight: 900
				align-items: center
				span
					+text(primary-500)
					position: relative
					top: r(2px)
	.accordion-title
		span
			top: 0
	.accordion-item.active
		.accordion-title
			span
				transform: rotate(90deg)
