.feedback-item
	display: flex
	gap: r(20px)
	+mq-max(lg)
		flex-direction: column
	&-img
		+mq-min(lg)
			+flex-width(224 / 640 * 100%)
		figure
			+res-ratio(224,176)
	&-caption
		flex: auto
		display: flex
		gap: r(20px)
	&-quote
		+flex-width(r(40px))
		+remove(lg,max)
	&-content
		flex: auto
	&-brief
		+line(5)
