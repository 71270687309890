.landing-v1-slider-1
	.item
		position: relative
		.icon
			position: absolute
			bottom: 0
			right: 0
			width: r(90px)
		.caption
			padding-right: r(64px)

.swiper-pagination-landing
	position: static
	display: flex
	justify-content: center
	gap: r(8px)
	.swiper-pagination-bullet
		+bg(primary-100)
		opacity: 1
		&-active
			+bg(primary-500)
