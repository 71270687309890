.section-doctor-intro
	background-position: top center
	background-repeat: no-repeat
	+mq-max(lg)
		background-image: none !important
		+bg(primary-50)
	+mq-min(xl)
		min-height: 27rem
		background-size: auto
	+mq-min(2xl)
		min-height: r(480px)
		background-size: cover
