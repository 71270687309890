.contact-info
	ul
		+layout-col(24px)
		li
			a
				display: flex
				gap: r(16px)
				@extend .body-4
				span
					+flex-width(16px)
					+text(primary-500)
					position: relative
					top: r(2px)
					+fz(16px)
	.map
		margin-top: r(36px)
		iframe
			height: r(306px)
			width: 100%
