.article-content
	ul
		list-style: disc
		padding-left: 0.75rem
		+content-margins
			margin-top: r(12px)
		li
			&::marker
				+text(primary-500)
	table
		td,th
			border: 1px solid #dee2e6
			padding: .75rem
			vertical-align: top
	h2
		@extend .body-1
		font-weight: 700
		+text(primary-500)
	h3
		@extend .body-2
		font-weight: 700
	h2 + *, h3 + *
		margin-top: r(12px) !important
	a
		&:not(.btn)
			+text(primary-600)
			+on-hover
				text-decoration: underline
	&.table-no-border
		table
			td,th
				border: 0
				padding: 0
			td + td
				padding-left: r(20px)
	&.table-bottom-border
		table
			tr
				+first(1)
					td
						padding-top: 0
			td,th
				border: 0
				border-bottom: 1px solid color(gray-200)
				padding-left: 0
				padding-right: 0
			td + td
				padding-left: r(20px)
	em,i
		font-style: italic

.expand-content
	&-container
		overflow: hidden
		+trans-all
	&-trigger
		+on-hover
			.ico-button-arrow
				&::before
					content: '\e900'
	&.show
		max-height: none !important

.product-detail-content
	table
		th
			+bg(primary-500)
			text-transform: uppercase
			+text(white)
			font-weight: 400
		th,td
			vertical-align: middle
			padding: r(12px) r(32px)
			text-align: left
		th
			border: 1px solid rgba(#fff , 0.3)
		td
			border: 1px solid color(primary-100)
		.fa-check
			+fz(24px)
			color: #00AA07

.table-responsive
	overflow: auto
