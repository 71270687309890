.landing-template-v3-3
	overflow: hidden
	.general-slider
		.swiper-container
			overflow: visible
		.swiper-slide
			.item
				+trans-all
				display: block
				.img
					&.has-video
						position: relative
						.play-icon
							position: absolute
							+center(both)
							+circle(80px)
							+bg-opa(primary-500,0.4)
							+text(white)
							display: flex
							+flex-center
							+fz(32px)
							border: 2px solid rgba(#fff , 0.8)
							+trans-all
							z-index: 2
				.caption
					opacity: 0
					+trans-all
			&-active
				.item
					+bg(gray-50)
					box-shadow: 4px 4px 16px 6px rgba(0, 0, 0, 0.08)
					.caption
						opacity: 1
