$directions: ( all, none, top, right, bottom, left )

@each $breakpoint, $breakpoint-value in $breakpoints
    @each $direction in $directions
        @each $color, $color-value in $border
            @if $direction == "all"
                $class-name: unquote("border-#{ $color }");
                .#{ $class-name }
                    border: 1px solid $color-value

            @else if $direction == "none"
                $class-name: unquote("border-none")
                .#{ $class-name }
                    border: none

            @else
                $class-name: unquote("border-#{ $direction }-#{ $color }");
                .#{ $class-name }
                    border-#{$direction}: 1px solid $color-value
            @media (min-width: $breakpoint-value)
                @if $direction == "all"
                    $class-name: unquote("border-#{ $breakpoint }-#{ $color }");
                    .#{ $class-name }
                        border: 1px solid $color-value

                @else if $direction == "none"
                    $class-name: unquote("border-#{ $breakpoint }-none")
                    .#{ $class-name }
                        border: none

                @else
                    $class-name: unquote("border-#{ $direction }-#{ $breakpoint }-#{ $color }");
                    .#{ $class-name }
                        border-#{$direction}: 1px solid $color-value
