.landing-page-v3-2
	.expand-content
		&-container
			max-height: r(160px)
			position: relative
		&-mask
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			background: rgb(255,255,255)
			background: linear-gradient(180deg, rgba(255,255,255,0.2) 0%, rgba(245,245,245,1) 100%)
		&-trigger
			border-bottom: 1px solid color(primary-500)
			em
				position: relative
				top: r(1px)
		&.show
			.expand-content-mask
				display: none
