.landing-template-v2-10
	overflow: hidden
	.general-slider
		.swiper-container
			overflow: visible
			.swiper-slide
				.item
					position: relative
					&::before
						+pseudo
						+fill
						background: linear-gradient(0deg, rgba(0, 0, 0, 0.36) 0%, rgba(0, 0, 0, 0.36) 100%)
						+trans-all
				&-active
					.item
						&::before
							opacity: 0
