.business-section-2
	.row
		+gap-override(32px, 40px)
	.item
		.img
			figure
				+res-ratio(640,426)

.business-popup
	h2
		@extend .body-1
		font-weight: 700
		+text(primary-500)
	table
		width: 100%
		tr
			td
				padding: r(20px) 0
				border-bottom: 1px solid #D9D9D9
			+first(1)
				td
					padding-top: 0
	.content
		padding-right: r(20px)
		max-height: r(280px)
		overflow: auto
		+scrollbars(r(3px), color(primary-500), color(primary-200))
