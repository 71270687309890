.news-detail-doctor
	box-shadow: 4px 4px 16px 6px rgba(0, 0, 0, 0.08)
	display: flex
	gap: r(24px)
	.img
		+flex-width(r(189px))
		+mq-max(md)
			+flex-width(r(120px))
		figure
			+res-ratio(1,1)
			border-radius: 50%
			overflow: hidden
	.caption
		flex: 1

.news-detail-divider
	width: r(190px)
	height: 1px
	+bg(secondary-500)
	margin: r(32px) 0

.news-detail
	.detail-share
		margin-top: r(16px)
		// +mq-max(xl)
		// +mq-min(xl)
		// 	position: absolute
		// 	top: 0
		// 	right: 100%
		// 	margin-right: r(32px)
		// 	height: 100%
		// 	ul
		// 		flex-direction: column
		// 		position: sticky
		// 		top: calc(var(--header-height) + 20px)
	&-other
		+content-margins
			margin-top: r(16px)
			padding-top: r(16px)
			border-top: 1px solid color(gray-100)

.news-detail-form-wrap
	display: none

.news-detail-page
	.news-detail-form-wrap
		display: block
