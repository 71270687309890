.landing-v2-gallery-slider
	.item
		.img
			figure
				position: relative
				figcaption
					position: absolute
					bottom: r(20px)
					left: r(20px)
					right: (20px)
					padding: r(32px)
					+bg-opa(white, 0.75)
					border-radius: r(8px)
					text-align: center
					+trans-all
					+mq-min(lg)
						opacity: 0
		+on-hover
			.img
				figure
					figcaption
						opacity: 1
