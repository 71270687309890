$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)

@each $color, $value in $button
	$hover-color: map-get($button-hover, $color)

	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, color(primary-500), #fff)
		+on-hover
			background-color: $hover-color
			color: contrast-color($hover-color, color(primary-500), #fff)
			.ico-button-arrow
				&::before
					content: '\e900'
// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.btn
	padding: r(10px) r(24px)
	display: inline-flex
	+trans-all
	+fz-lh(18px,24px)
	+flex-center
	border-radius: 48px
	white-space: nowrap
	letter-spacing: r(0.28px)
	em
		+fz(16px)
	&:hover
		border-radius: r(4px)

	+content-margins
		display: inline-block
		margin-left: r(8px)
	&-sm
		padding: r(8px) r(16px)
		+fz-lh(14px,16px)

.swiper-btn
	display: inline-flex
	+flex-center
	+circle(64px)
	+text(primary-500)
	font-size: r(32px)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	+bg(white)
	border: 1px solid color(primary-500)
	// +remove(lg,max)
	+mq-max(lg)
		width: r(40px)
		height: r(52px)
		font-size: r(24px)
		+bg-opa(white, 0.75)
	+on-hover
		+bg(primary-500)
		+text(white)
	&.swiper-button-disabled
		opacity: 0
		pointer-events: none
	&.no-box
		width: auto
		height: auto
		background: transparent
		border: 0
		+text(gray-300)
		+on-hover
			+text(primary-500)

.swiper-navigation
	&.is-between
		.swiper-btn
			position: absolute
			+center(Y)
			margin: 0 r(30px)
			&.swiper-prev
				right: 100%
				+mq-max(lg)
					right: auto
					left: 0
					border-radius: 0 50% 50% 0
					margin-left: r(-16px)
			&.swiper-next
				left: 100%
				+mq-max(lg)
					left: auto
					right: 0
					border-radius: 50% 0 0 50%
					margin-right: r(-16px)
			+mq-max(2xl)
				margin: 0 r(10px)
			+mq-max(lg)
				margin: 0

		&.is-no-gap
			.swiper-btn
				margin: 0 r(20px)
				&.swiper-prev
					right: auto
					left: 0
				&.swiper-next
					left: auto
					right: 0
	&.is-left
		display: flex
		gap: r(12px)

.social-list
	ul
		display: flex
		gap: r(8px)
	a
		+circle(48px)
		display: flex
		+flex-center
		+bg(primary-500)
		+text(white)
		+fz(24px)
	img
		max-width: r(32px)
	&-small
		a
			+circle(36px)
			+fz(20px)
