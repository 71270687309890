.cart
	text-align: center
	position: relative
	.cart-toggle
		cursor: pointer
		+fz(16px)
		+text(gray-500)
		position: relative
		+circle(32px)
		+bg(white)
		display: flex
		+flex-center
		+on-hover
			+bg(primary-500)
			+text(white)
		.cart-amount
			position: absolute
			top: r(-8px)
			right: r(-8px)
			display: flex
			+flex-center
			+circle(24px)
			background-color: #E30018
			+fz(12px)
			font-weight: 700
			+text(white)
			+mq-max(lg)
				+circle(16px)
	.cart-dropdown
		position: absolute
		top: 100%
		right: 0
		width: r(400px)
		background: #fff
		+text(gray-700)
		border-radius: r(8px)
		box-shadow: 0px 2px 4px rgba(137, 137, 137, 0.25)
		z-index: 20
		display: none
		margin-top: r(16px)
		z-index: 51
		&.show
			display: block

	.cart-heading
		display: flex
		align-items: center
		justify-content: space-between
		border-bottom: 2px solid color(gray-50)
		margin-bottom: r(8px)
		padding: r(42px) r(16px) r(12px)
		+fz(14px)
		+text(gray-800)

	.cart-close
		+fz(14px)
		cursor: pointer
		position: absolute
		top: r(12px)
		right: r(12px)
		+text(primary-600)

	.cart-body
		overflow-y: auto
		height: r(228px)
		ul
			li
				& + li
					border-top: 1px dashed #D9D9D9

	.cart-product
		padding: r(16px)
		.img
			display: block
			+flex-width(r(44px))
		.caption
			flex: 1
			text-align: left
			padding-left: r(12px)
		.name
			display: block
			margin-bottom: r(16px)
			font-weight: 600
			&:hover
				+text(primary-500)
		.quantity
			+text(gray-800)
		.price
			font-weight: 600

	.cart-button
		padding: r(20px) r(20px)
		a
			white-space: nowrap
	@media screen and ( max-width: 576px )
		position: static
