//Reset
@import src/_plugins/sass-libraries/reset.sass
@import src/_plugins/sass-libraries/util.scss
// @import src/_plugins/sass-libraries/fluid-type.sass
@import src/_plugins/sass-libraries/family.scss
@import src/_plugins/sass-libraries/columnizer.scss
@import src/_plugins/sass-libraries/svg-background.scss

//Gridflex
$gl-gridName: row
$gl-gutter: r(32px)
$gl-gutter-vertical: r(32px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
html
	font-size: 3.6vw
	+mq-min(sm)
		font-size: 2.4vw
	+mq-min(md)
		font-size: 1.5vw
	+mq-min(lg)
		font-size: 1.2vw
	+mq-min(3xl)
		font-size: 1vw
body
	line-height: 1
	font-family: 'Quicksand'
	+text(gray-900)
	font-weight: 500
	+mq-min(xl)
		font-size: r(16px)

*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none

.swiper-equal-height
	.swiper-slide
		height: auto
		> *
			height: 100%

.fa-exclamation-triangle
	line-height: 2.5
	color: #ff3333
	font-weight: 400
	font-family: 'Signika'
	&:before
		font-family: 'Font Awesome 6 Pro'

.frm-btn-reset
	display: none !important

.frm-btnwrap
	.label
		display: none

.divider
	height: 1px
	width: r(80px)
	display: inline-block

.swiper-pagination
	display: flex
	gap: r(12px)
	justify-content: center
	&-bullet
		opacity: 0.5
		+bg(white)
		+trans-all
		+box(12px)
		&-active
			border-radius: 0
			opacity: 1
			+bg(primary-500)

#popupPhoneCall
	width: r(600px)
