.about-section-4
	.row
		+mq-min(lg)
			+gap-override(52px)
	.item
		box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.10)
		@extend .img-zoom-out
		+trans-all
		.img
			figure
				+res-ratio(630,332)
				overflow: hidden
				img
					+trans-all
		.title
			padding: r(16px)
			+mq-min(lg)
				padding: r(16px) r(96px)
		+on-hover
			+bg(primary-500)
			.title
				color: white !important
