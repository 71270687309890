.manual-nav-wrap
	.tabs
		.tabs-nav
			+mq-min(lg)
				+scrollbars(2px, color(primary-300), color(gray-100))
				padding-bottom: r(4px)
			li
				padding-bottom: r(10px)
				a
					+bg(white)
					box-shadow: 0px r(6px) r(8px) 0px rgba(0, 0, 0, 0.10)
