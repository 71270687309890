.global-footer
	background: linear-gradient(135deg, #F6EEF4 0%, #EEDDE8 100%)

.logo-footer
	width: r(124px)
	a
		display: block
		+res-ratio(124,120)

.footer-item
	h3
		@extend .heading-5
		font-weight: 700
		+text(primary-500)
		margin-bottom: r(16px)
	&-info
		display: flex
		flex-direction: column
		justify-content: space-between
		ul
			margin-top: auto
			+layout-col(12px)
			li
				a
					display: flex
					gap: r(16px)
					@extend .body-4
					span
						+flex-width(16px)
						+text(primary-500)
						position: relative
						top: r(2px)
						+fz(16px)
	&-util
		.footer-map
			iframe
				height: r(112px)
				width: r(224px)
				box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.10)
				+mq-max(md)
					width: 100%
					height: r(200px)
		
		.footer-cert
			ul
				display: flex
				gap: r(8px)