.tag-list
	ul
		display: flex
		gap: r(8px)
		flex-wrap: wrap
		li
			a
				display: inline-flex
				align-items: center
				padding: r(4px) r(20px)
				white-space: nowrap
				+bg(gray-50)
				+text(gray-500)
				border-radius: 999px
				@extend .body-4
				+trans-all
				+mq-max(sm)
					padding: r(8px) r(20px)
				+on-hover
					background: linear-gradient(90deg, #FFDE94 0%, #FFEBB8 50%, #FFD37E 100%)
			&.active
				a
					background: linear-gradient(90deg, #FFDE94 0%, #FFEBB8 50%, #FFD37E 100%)
	&-news
		ul
			li
				a
					border-radius: r(4px)
					+bg(primary-50)
