.circle-nav
	position: relative
	.img
		position: relative
		text-align: center
		display: flex
		align-items: center
		+mq-max(lg)
			display: none
		+mq-min(lg)
			width: r(248px)
			height: r(390px)
			&::after
				content: ''
				position: absolute
				height: r(390px)
				width: r(186px)
				left: -3.15rem
				+center(Y)
				z-index: -1
				background: white
				+mq-min(lg)
					left: -3.45rem
				+mq-min(xl)
					left: r(-73px)

			&::before
				content: ''
				position: absolute
				height: r(390px)
				width: r(186px)
				background-image: url(../img/half-circle.svg)
				right: -3.2rem
				+center(Y)
				z-index: -1
				background-position: right
				background-size: cover
				background-repeat: no-repeat
				+mq-min(lg)
					right: -2.5rem
				+mq-min(xl)
					right: r(-50px)
	.circle-nav-tab
		ul
			+mq-max(lg)
				display: flex
				gap: r(16px)
				overflow: auto
			li
				+mq-min(lg)
					position: absolute
					width: r(224px)
				&.active
					a
						+text(primary-500)
				a
					display: inline-flex
					gap: r(20px)
					align-items: center
					+mq-min(lg)
						span
							+line(2)
						&::before
							content: ""
							display: block
							+circle(15px)
							+bg(gray-500)
							+flex-width(r(15px))
					+mq-max(lg)
						white-space: nowrap
						display: inline-flex
						+bg(primary-50)
						padding: r(12px) r(20px)
						border-radius: r(8px)
				&:nth-child(1)
					top: r(16px)
					right: 0
				&:nth-child(2)
					top: r(89px)
					right: r(-58px)
				&:nth-child(3)
					top: r(169px)
					right: r(-76px)
				&:nth-child(4)
					bottom: r(89px)
					right: r(-56px)
				&:nth-child(5)
					bottom: r(16px)
					right: 0
				+mq-max(2xl)
					&:nth-child(1)
						right: r(32px)
					&:nth-child(2)
						right: r(-24px)
					&:nth-child(3)
						right: r(-44px)
					&:nth-child(4)
						right: r(-24px)
					&:nth-child(5)
						right: r(32px)

.landing-template-v1-12
	.section-content
		+mq-min(lg)
			position: relative
			&::before
				content: ""
				right: 0
				top: 0
				bottom: 0
				+bg(gray-50)
				border-radius: r(20px)
				position: absolute
				width: 1200 / 1312 * 100%
				z-index: -1
	#tabContainer2
		+mq-min(lg)
			min-height: r(708px)
			display: flex
			flex-direction: column
			justify-content: center
		+mq-max(lg)
			margin-top: r(32px)
