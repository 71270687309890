.product-detail-thumbnail
	margin-top: r(12px)
	padding: 0 r(40px)
	+mq-max(lg)
		margin-top: r(16px)
	.img
		figure
			border-radius: r(16px)
			+res-ratio(180,100)
			overflow: hidden
		&.video
			figure
				figcaption
					position: absolute
					+center(both)
					+circle(40px)
					+bg-opa(primary-500,0.4)
					+text(white)
					display: flex
					+flex-center
					+fz(16px)
					border: 2px solid rgba(#fff , 0.8)
					+trans-all

.product-detail-brief
	+mq-min(lg)
		height: r(88px)
		overflow: hidden
		text-overflow: ellipsis
		+line(4)

.product-detail-images
	.img
		figure
			border-radius: r(16px)
			+res-ratio(864,486)
			overflow: hidden
			img
				object-fit: contain
		&.video
			figure
				figcaption
					position: absolute
					+center(both)
					+circle(64px)
					+bg-opa(primary-500,0.4)
					+text(white)
					display: flex
					+flex-center
					+fz(32px)
					border: 2px solid rgba(#fff , 0.8)
					+trans-all

.spin-input-wrap
	width: r(144px)
	+layout-row(4px)
	height: r(48px)
	border: 1px solid color(primary-100)
	padding: r(4px)
	align-items: center
	justify-content: space-between
	+radius(999px)
	.btn-spin,.spin-btn
		display: flex
		+flex-center
		+text(primary-500)
		+bg(primary-50)
		cursor: pointer
		+circle(40px)
		+flex-width(r(40px))
	input
		border: 0
		text-align: center
		+flex-width(r(40px))

.product-detail-attributes
	.tabs-nav
		display: flex
		gap: r(8px)
		li
			&.active
				a
					+bg-gra(linear-1)
	.expand-content
		&-container
			max-height: r(340px)

.product-detail-form-wrap
	padding-bottom: r(40px)
	display: none
	+mq-min(lg)
		padding-bottom: r(140px)

.product-detail-form-wrap-append
	.product-detail-form-wrap
		display: block

.product-detail-content
	table
		td
			> * + *
				margin-top: r(5px)
