.doctor-detail-intro
	background-size: cover
	background-position: top
	background-repeat: no-repeat
	position: relative
	overflow: hidden
	+mq-max(lg)
		background-image: none !important
		background-color: #d9d9d9
	+mq-min(lg)
		&::before
			+pseudo
			width: 100%
			height: r(84px)
			+bg-img-cover("../img/doctor-divider.png")
			bottom: 0
			left: 0

.doctor-detail-info.article-content
	h1
		@extend .heading-2
		+text(primary-500)
		font-weight: 700
		margin-bottom: r(4px)
	h2
		+text(gray-800)
		@extend .body-1
		font-weight: 500
		margin-top: 0
		text-transform: uppercase
	.btn-group
		margin-top: r(24px)

.doctor-detail-attributes
	.attr-item
		&-title
			.icon
				+flex-width(r(50px))
		&-content
			.article-content
				* + ul
					margin-top: r(8px) !important
		&-side-list
			border-left: r(4px) solid color(primary-500)
