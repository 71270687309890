.landing-template-v2-3
	.item
		border-radius: r(16px)
		border: r(5px) solid color(gray-100)
		position: relative
		transition: all 0.2s ease
		&::before
			+pseudo
			+box(40px)
			border-radius: r(16px) 0
			background-image: linear-gradient(#B6478E 0%, #FFBBE6 20%, #B6478E 100%)
			top: r(-5px)
			left: r(-5px)
		.item-inner
			transition: all 0.2s ease
			position: relative
			z-index: 2
			border-radius: r(10px)
		.img
			img
				transition: all 0.2s ease

		+on-hover
			border-color: color(primary-500)
			&::before
				opacity: 0
			.item-inner
				+bg(primary-500)
				+text(white)
			.img
				img
					filter: brightness(0) invert(1)
