.about-section-3
	overflow: hidden
	+mq-min(lg)
		padding-top: r(105px) !important
	.img
		width: r(468px)
		margin-left: auto
		+mq-max(lg)
			margin: 0 auto
	.zone-content
		.zone-heading
			position: relative
			+mq-max(lg)
				display: flex
				gap: r(16px)
				align-items: center
			.icon
				position: absolute
				right: 100%
				top: 0
				margin-right: r(28px)
				width: r(72px)
				+mq-max(2xl)
					margin-right: r(12px)
				+mq-max(lg)
					position: static

	.core-list
		.row
			+gap-override(12px, 24px)
		.item
			+bg(white)
			padding: r(16px) r(40px)
			position: relative
			clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)
			aspect-ratio: 205 / 178
			width: 100%
			+mq-max(2xl)
				padding-top: r(32px)
			&:after
				content: ""
				position: absolute
				top: 2px
				left: 2px
				right: 2px
				bottom: 2px
				margin: 0 auto
				background: #FBE8F5
				opacity: 0.25
				clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)
				z-index: -1
			.divider
				margin: r(8px) auto
				width: r(30px)
				height: 1px
				+bg(primary-500)
			.content
				+line(7)
