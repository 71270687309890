.inpatient-detail-content
	table
		width: 100%
		tr
			+first(1)
				td
					padding-top: 0
			td
				padding: r(12px) 0
				border-bottom: 1px solid #DDD
				font-weight: 700
				width: 50%
				+first(1)
					padding-right: r(16px)
				&:nth-child(2)
					+text(primary-500)

.inpatient-detail-content-wrap
	display: flex
	flex-direction: column
	align-items: flex-start
	.social-list
		margin-top: auto
