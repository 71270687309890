.fixed-stuff
	position: fixed
	z-index: 50
	bottom: r(60px)
	right: r(20px)
	.ActionMenuBody
		left: auto !important
		right: 100%
	ul
		li
			& + li
				margin-top: r(8px)
			.btn-circle
				+flex-center
				+reset-link
				display: inline-flex
				+circle(60px)
				font-size: r(32px)
				transition: 0.3s all
				flex-direction: column
				gap: r(4px)
				em
					font-size: r(24px)
			img
				max-width: r(40px)
			&.has-tooltip
				position: relative
				z-index: 1
				.tooltip
					position: absolute
					right: 0
					top: 0
					bottom: 0
					white-space: nowrap
					display: flex
					align-items: center
					justify-content: center
					flex-direction: column
					+bg(white)
					+text(primary-500)
					border-radius: 999px
					z-index: -1
					padding: 0
					overflow: hidden
					opacity: 0
					pointer-events: none
					+trans-all
					+fz(20px)

				&:hover
					.tooltip
						opacity: 1
						pointer-events: all
						padding: 0 r(80px) 0 r(30px)

.fixed-cta
	position: fixed
	z-index: 50
	top: r(140px)
	right: r(4px)
	.ActionMenuBody
		left: auto !important
		right: 100%
	+mq-max(lg)
		top: calc( var(--header-height) + 1.04166667rem )
		right: -100%
		+trans-all
		&.show
			right: r(4px)
	ul
		+layout-col(4px)
		align-items: flex-end
		li
			a
				display: flex
				height: r(80px)
				align-items: center
				+text(primary-500)
				+bg(white)
				border-radius: r(16px)
				border: 1px solid color(primary-500)
				overflow: hidden
				width: r(80px)
				+trans-all
				+mq-max(lg)
					padding: r(6px)
					width: r(96px)
					height: r(96px)
				+on-hover
					padding: r(6px) r(8px)
					width: r(214px)
					border-radius: r(16px) 0px 0px r(16px)
					.text
						opacity: 1
				.icon
					display: flex
					flex-direction: column
					gap: r(4px)
					align-items: center
					text-align: center
					justify-content: center
					padding: 0 r(4px)
					+mq-min(lg)
						+flex-width(r(76px))
					em
						+fz(32px)
						+mq-max(lg)
							+fz(24px)
					strong
						font-size: r(12px)
						line-height: 1.375
				.text
					display: inline-block
					padding-left: r(8px)
					border-left: 1px solid color(primary-500)
					@extend .body-4
					font-weight: 700
					opacity: 0
					+trans-all
					height: r(60px)
					+line(3)
					+mq-max(lg)
						display: none
