.home-banner-slider
	.swiper-pagination
		position: absolute
		+center(X)
		bottom: r(98px)
		&-bullet
			&-active
				+bg(white)
	.banner-item
		&-img
			figure
				+mq-max(md)
					+res-ratio(3,4)

.home-helper-section
	+mq-max(lg)
		padding: r(32px) 0
	+mq-min(lg)
		position: absolute
		bottom: 0
		left: 0
		width: 100%
		transform: translateY(50%)
		z-index: 2
	.helper-wrap
		box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.10)
		.btn
			+mq-min(lg)
				width: r(260px)