.about-section-1
	background-position: top center !important
	padding: r(48px) 0
	position: relative
	z-index: 2
	+mq-min(lg)
		padding: r(158px) 0 r(105px)
		min-height: r(853px)
	.content-wrap
		+mq-min(lg)
			margin-left: r(-60px)
			padding-right: r(32px)
	.quote-content
		position: relative
		.quote-right
			text-align: right
			margin-top: r(12px)
			+mq-min(lg)
				position: absolute
				top: 100%
				right: 0
	.container
		z-index: 2

.landing-images
	.img
		border: r(10px) solid color(white)
		border-radius: r(16px)
		position: relative
		display: block
		figure
			border-radius: r(8px)
			overflow: hidden
			+res-ratio(752,423)
		.icon
			position: absolute
			+center(both)
			+circle(92px)
			+bg-opa(primary-500,0.4)
			+text(white)
			display: flex
			+flex-center
			+fz(48px)
			border: 2px solid rgba(#fff , 0.8)
			+trans-all
		+on-hover
			.icon
				+bg-opa(primary-500,0.8)
				border: 2px solid rgba(#fff , 1)

.landing-thumbnail
	padding-top: r(12px)
	+mq-min(lg)
		margin: 0 r(114px)
	.img
		figure
			+res-ratio(153,87)
			border: 2px solid transparent
			+trans-all
	.swiper-slide-thumb
		&-active
			.img
				figure
					border: 2px solid color(primary-500)

.landing-images-wrap
	.swiper-pagination
		position: static
		margin-top: r(40px)
		+mq-max(lg)
			margin-top: r(20px)

section
	scroll-margin-top: calc( var(--header-height) + 2rem )
