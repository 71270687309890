.slidenav
	.swiper-slide
		width: max-content
	.nav-item
		a
			display: block
			// white-space: nowrap
			padding: r(12px) r(24px)
			@extend .body-3
			+text(gray-800)
			border-bottom: 1px solid transparent
			text-align: center
		&.active
			a
				+text(primary-500)
				border-bottom: 1px solid color(primary-500)

.landing-template-v2-2
	.item-wrap
		&:not(.first-column)
			.item
				padding-left: r(32px)
				border-left: 1px solid color(primary-100)
	.item
		table
			width: 100%
			tr
				td
					padding: r(12px) 0
					border-bottom: 1px solid color(gray-100)
				&:last-child
					td
						border-bottom: none
