.about-section-8
	.item
		box-shadow: 0px 5px 25px 10px rgba(0, 0, 0, 0.10)
		+trans-all
		.img
			figure
				+res-ratio(416,328)
		.title
			+trans-all
		+on-hover
			+bg(primary-500)
			.title
				+text(white)
